import { useEffect, useState, useRef } from "react";
import "../components/BookCar.scss";
import config from "../config";
import "./BookCar.scss";
import Sedan from "../images/cars-big/Sedan.jpg";
import Hatchback from "../images/cars-big/Hatchback.jpg";
import SUV from "../images/cars-big/SUV.jpg";
import CompactSUV from "../images/cars-big/CompactSUV.jpg";
import Swift from "../images/cars-big/swift.jpg";
import SwiftDzire from "../images/cars-big/dzire.jpg";
import Baleno from "../images/cars-big/baleno.jpg";
import Ecosport from "../images/cars-big/EcoSport.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faUser,
  faMotorcycle,
  faIndianRupeeSign,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";

function Vendor() {
  const [vendorName, setVendorName] = useState("");
  const [vendorMobNum, setVendorMobNum] = useState("");
  const [vendorAddress, setVendorAddress] = useState("");
  const [vendorVehicleName, setVendorVehicleName] = useState("");
  const [vendorVehicleAmount, setVendorVehicleAmount] = useState("");
  const [vendorVehicleSeat, setVendorVehicleSeat] = useState("");
  const [vendorVehiclePollutionExpiry, setVendorVehiclePollutionExpiry] =
    useState("");
  const [vendorVehicleInsuranceExpiry, setVendorVehicleInsuranceExpiry] =
    useState("");
  const vehicleImgInputRef = useRef(null);
  const [vendorVehicleImageFile, setVendorVehicleImageFile] = useState(null);
  const [registerType, setRegisterType] = useState("");
  const [vendorSpinnerFlag, setVendorSpinnerFlag] = useState(false);
  const [vendorVehicleType, setVendorVehicleType] = useState("");
  const [vendorVehicleFuelType, setVendorVehicleFuelType] = useState("");
  const [vehicleBookingStatus, setVehicleBookingStatus] = useState("No");
  const [vendorPassword,setVendorPassword] = useState("");

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handlevendorName = (e) => {
    const value = e.target.value;
    const regex = /^[A-Za-z ]*$/;
    if (value === "" || regex.test(value)) {
      setVendorName(value);
    }
  };

  const handleMobNum = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;
    if (value === "" || regex.test(value)) {
      setVendorMobNum(value);
    }
  };

  const handleVendorVehicleAmount = (e) => {
    setVendorVehicleAmount(e.target.value);
  };

  const handleVendorVehicleSeat = (e) => {
    setVendorVehicleSeat(e.target.value);
  };

  const handleVendorVehicleName = (e) => {
    setVendorVehicleName(e.target.value);
  };

  const handleVendorAddress = (e) => {
    setVendorAddress(e.target.value);
  };

  const handleVendorVehiclePollutionExpiry = (e) => {
    setVendorVehiclePollutionExpiry(e.target.value);
  };

  const handleVendorVehicleInsuranceExpiry = (e) => {
    setVendorVehicleInsuranceExpiry(e.target.value);
  };

  const onVehicleImgUpload = (e) => {
    setVendorVehicleImageFile(e.target.files[0]);
  };

  const handleRegisterType = (e) => {
    setRegisterType(e.target.value);
  };

  const handleVendorVehicleType = (e) => {
    setVendorVehicleType(e.target.value);
  };

  const handleVendorVehicleFuelType = (e) => {
    setVendorVehicleFuelType(e.target.value);
  };

  const handleVehicleBookingStatus = (e) => {
    setVehicleBookingStatus(e.target.value);
  };

  const handleVendorPassword = (e) => {
    setVendorPassword(e.target.value);
  }

  const registerVendor = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";

    const errorMsg = document.querySelector(".error-message");
    if (
      vendorName === "" ||
      vendorMobNum === "" ||
      vendorAddress === "" ||
      vendorVehicleName === "" ||
      vendorVehicleAmount === "" ||
      vendorVehicleSeat === "" ||
      vendorVehiclePollutionExpiry === "" ||
      vendorVehicleInsuranceExpiry === "" ||
      vendorVehicleImageFile === null ||
      registerType === "" ||
      vendorVehicleFuelType === "" ||
      vendorVehicleType === "" || 
      vendorPassword === ""
    ) {
      errorMsg.style.display = "flex";
    } else {
      const vehicleImageFileSizeInMB =
        vendorVehicleImageFile.size / 1024 / 1024;
      if (vehicleImageFileSizeInMB > 5) {
        alert("Vehicle size cannot be more than 5 MB");
      } else {
        setVendorSpinnerFlag(true);
        const vendorVehicleInfoReq = {
          vendorName,
          vendorMobNum,
          vendorAddress,
          vendorVehicleName,
          vendorVehicleAmount,
          vendorVehicleSeat,
          vendorVehiclePollutionExpiry,
          vendorVehicleInsuranceExpiry,
          registerType,
          vendorVehicleFuelType,
          vendorVehicleType,
          vehicleBookingStatus,
          vendorPassword
        };

        const formData = new FormData();
        formData.append("vendorVehicleImageFile", vendorVehicleImageFile);
        formData.append(
          "vendorVehicleInfoReq",
          JSON.stringify(vendorVehicleInfoReq)
        );

        try {
          let url = config.apiUrl + "/registerVendor";
          const response = await fetch(url, {
            method: "POST",
            body: formData,
          });

          const data = await response.json();
          if (response.ok) {
            setSuccessMsg("Vendor Registered Successfully.");
            setVendorName("");
            setVendorMobNum("");
            setVendorAddress("");
            setVendorVehicleName("");
            setVendorVehicleAmount("");
            setVendorVehicleSeat("");
            setVendorVehiclePollutionExpiry("");
            setVendorVehicleInsuranceExpiry("");
            setRegisterType("");
            setVendorVehicleFuelType("");
            setVendorVehicleType("");
          } else {
            setErrorMsg("Network response was not ok.");
            throw new Error("Network response was not ok");
          }
        } catch (error) {
          setErrorMsg(
            "Our system is currently undergoing maintenance. Please try again later."
          );
          console.error("Error fetching data:", error);
        } finally {
          setVendorSpinnerFlag(false);
          errorMsg.style.display = "none";
          setVendorVehicleImageFile(null);
          if (vehicleImgInputRef.current) {
            vehicleImgInputRef.current.value = "";
          }
        }
      }
    }
  };

  useEffect(() => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  }, []);

  useEffect(() => {
    if (successMsg) {
      const timer = setTimeout(() => {
        setSuccessMsg("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMsg]);

  useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => {
        setErrorMsg("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMsg]);

  return (
    <>
      {vendorSpinnerFlag ? (
        <div className="spinner-container spinner-style">
          <ClipLoader
            color="#123abc"
            vendorSpinnerFlag={vendorSpinnerFlag}
            size={200}
          />
        </div>
      ) : (
        <section>
          {errorMsg ? <div className="error-msg">{errorMsg}</div> : null}
          {successMsg ? (
            <div
              className="booking-done"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {successMsg}
            </div>
          ) : null}

          <form className="box-form">
            <div className="box-form__car-type">
              <label>
                Name <b>*</b>
              </label>
              <input
                type="text"
                className="test-box input-box-clour"
                maxLength={30}
                placeholder="Enter your Name"
                value={vendorName}
                onChange={handlevendorName}
              ></input>
            </div>
            <div className="box-form__car-type">
              <label>
                Password <b>*</b>
              </label>
              <input
                type="text"
                className="test-box input-box-clour"
                maxLength={30}
                placeholder="Enter your Password"
                value={vendorPassword}
                onChange={handleVendorPassword}
              ></input>
            </div>
            <div className="box-form__car-type">
              <label>
                Mobile Number <b>*</b>
              </label>
              <input
                className="test-box input-box-clour"
                value={vendorMobNum}
                onChange={handleMobNum}
                type="tel"
                placeholder="Enter your mobile number"
                maxLength={12}
                minLength={12}
              ></input>
            </div>
            <div className="box-form__car-type">
              <label>
                Address <b>*</b>
              </label>
              <input
                type="text"
                className="test-box input-box-clour"
                maxLength={100}
                placeholder="Ender your address"
                value={vendorAddress}
                onChange={handleVendorAddress}
              ></input>
            </div>
            <div className="box-form__car-type">
              <label>
                Vehicle Name <b>*</b>
              </label>
              <input
                type="text"
                className="test-box input-box-clour"
                maxLength={20}
                placeholder="Ender your vehicle name"
                value={vendorVehicleName}
                onChange={handleVendorVehicleName}
              ></input>
            </div>
            <div className="box-form__car-type">
              <label>
                Registration Type <b>*</b>
              </label>
              <select
                required
                value={registerType}
                onChange={handleRegisterType}
                className="input-box-clour"
              >
                <option>Select</option>
                <option value="With Driver">With Driver</option>
                <option value="Without Driver">Without Driver</option>
                <option value="Both">Both</option>
              </select>
            </div>
            <div className="box-form__car-type">
              <label>
                Booking Status <b>*</b>
              </label>
              <select
                disabled
                value={vehicleBookingStatus}
                onChange={handleVehicleBookingStatus}
                className="input-box-clour"
              >
                <option>Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
            <div className="box-form__car-type">
              <label>
                Vehicle Type <b>*</b>
              </label>
              <select
                required
                value={vendorVehicleType}
                onChange={handleVendorVehicleType}
                className="input-box-clour"
              >
                <option>Select Vehicle Type</option>
                <option value="Automatic">Automatic</option>
                <option value="Manual">Manul</option>
              </select>
            </div>
            <div className="box-form__car-type">
              <label>
                Fuel Type <b>*</b>
              </label>
              <select
                required
                value={vendorVehicleFuelType}
                onChange={handleVendorVehicleFuelType}
                className="input-box-clour"
              >
                <option>Select Fuel Type</option>
                <option value="Petrol">Petrol</option>
                <option value="Diesel">Diesel</option>
                <option value="Petrol/CNG">Petrol/CNG</option>
              </select>
            </div>
            <div className="box-form__car-type">
              <label>
                Amount <b>*</b>
              </label>
              <input
                type="text"
                className="test-box input-box-clour"
                maxLength={20}
                placeholder="Enter Amount"
                value={vendorVehicleAmount}
                onChange={handleVendorVehicleAmount}
              ></input>
            </div>
            <div className="box-form__car-type">
              <label>
                Seating Capacity <b>*</b>
              </label>
              <select
                required
                value={vendorVehicleSeat}
                onChange={handleVendorVehicleSeat}
                className="input-box-clour"
              >
                <option>Select</option>
                <option value="5">5</option>
                <option value="7">7</option>
              </select>
            </div>
            <div className="box-form__car-type">
              <label>
                Polution Expiry Date <b>*</b>
              </label>
              <input
                type="date"
                className="test-box input-box-clour"
                maxLength={1}
                placeholder="Enter polution expiry date"
                value={vendorVehiclePollutionExpiry}
                onChange={handleVendorVehiclePollutionExpiry}
              ></input>
            </div>
            <div className="box-form__car-type">
              <label>
                Ensurance Expiry Date <b>*</b>
              </label>
              <input
                type="date"
                className="test-box input-box-clour"
                maxLength={1}
                placeholder="Enter ensurance expiry date"
                value={vendorVehicleInsuranceExpiry}
                onChange={handleVendorVehicleInsuranceExpiry}
              ></input>
            </div>
            <div className="box-form__car-type">
              <label>
                Upload Vehicle Image <b>*</b>
              </label>
              <input
                className="vendor-img-style"
                type="file"
                accept=".jpg,.jpeg"
                onChange={onVehicleImgUpload}
                ref={vehicleImgInputRef}
              ></input>
            </div>

            <button onClick={registerVendor} type="submit">
              Register Vendor
            </button>
          </form>
        </section>
      )}
    </>
  );
}

export default Vendor;
