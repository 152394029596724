import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { useNavigate } from "react-router-dom";
import "../Pages/SignIn.scss";
import config from "../config";
import { ClipLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faUser,
  faMotorcycle,
  faIndianRupeeSign,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

function VendorLogin() {
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [adminFlag, setAdminFlag] = useState(true);
  const [spinnerFlag, setSpinnerFlag] = useState(false);
  const [userInfoRespList, setUserInfoRespList] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    name: "",
  });

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const confirmAccess = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setAdminFlag(false);
    setSpinnerFlag(true);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/vendorLogin";
      } else {
        url = config.apiUrl + "/vendorLogin";
      }

      const vendorData = {
        userName: userName,
        password: password,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(vendorData),
      });

      if (response.ok) {
        const data = await response.json();
        getVendorBookingDetails(data.vendorId);
        setAdminFlag(false);
      } else {
        setAdminFlag(true);
        setErrorMsg(userName + " don't have the necessary access permissions.");
      }
    } catch (error) {
      setAdminFlag(true);
      setErrorMsg(userName + " don't have the necessary access permissions.");
      console.error("Error in fetching data:", error);
    } finally {
      setUserName("");
      setPassword("");
      setSpinnerFlag(false);
    }
  };

  const getVendorBookingDetails = async (vendorId) => {
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/vendorBookingDetails";
      } else {
        url = config.apiUrl + "/vendorBookingDetails";
      }

      const vendorData = {
        vendorId: vendorId,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(vendorData),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          setUserInfoRespList(data);
        }
      } else {
        setErrorMsg("Error in fetching data.");
      }
    } catch (error) {
      setErrorMsg("Error in fetching data.");
      console.error("Error in fetching data:", error);
    } finally {
    }
  };

  useEffect(() => {
    if (userInfoRespList) {
      const filtered = userInfoRespList.filter((response) => {
        return (
          !filters.firstName ||
          response.firstName
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  }, [filters, userInfoRespList]);

  return (
    <>
      <section className="admin-page">
        <HeroPages name="Vendor Login" />
        {adminFlag ? (
          <div className="container">
            {errorMsg ? <div className="error-msg">{errorMsg}</div> : null}

            <div className="admin-div">
              <div className="admin-div__text">
                <h2>Only Vendor Can Access This.</h2>
              </div>
              <div className="admin-div__form">
                <form>
                  <label>
                    User Name <b>*</b>
                  </label>
                  <input
                    type="text"
                    required
                    value={userName}
                    onChange={handleUserName}
                    placeholder='E.g: "Abc@123"'
                  ></input>
                  <label>
                    Password <b>*</b>
                  </label>
                  <input
                    type="password"
                    required
                    value={password}
                    onChange={handlePassword}
                    placeholder="password"
                  ></input>
                  <button type="submit" onClick={confirmAccess}>
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                    Vendor Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        ) : spinnerFlag ? (
          <div className="spinner-container spinner-style">
            <ClipLoader color="#123abc" spinnerFlag={spinnerFlag} size={200} />
          </div>
        ) : (
          <div className="container">
            {userInfoRespList ? (
              <div className="table-container">
                <div className="filters-style">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="Filter By first name"
                    value={filters.firstName}
                    onChange={handleFilterChange}
                    className="filter-input-box"
                  />
                </div>
                <table className="responsive-table">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Age</th>
                      {/* <th>Phone NO</th> */}
                      <th>Pick UP Location</th>
                      <th>Drop OFF Location</th>
                      <th>Pick UP Date</th>
                      <th>Pick UP Time</th>
                      <th>Drop OFF Date</th>
                      <th>Drop OFF Time</th>
                      <th>Car Type</th>
                      <th>Create Date & Time</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData &&
                      filteredData.map((response) => (
                        <tr key={response.userId}>
                          <td data-label="First Name">{response.firstName}</td>
                          <td data-label="Last Name">{response.lastName}</td>
                          <td data-label="Age">{response.age}</td>
                          {/* <td data-label="Phone NO">
                              {response.phoneNumber}
                            </td> */}
                          <td data-label="Pick UP Location">
                            {response.pickUpLocation}
                          </td>
                          <td data-label="Drop OFF Location">
                            {response.dropOffLocation}
                          </td>
                          <td data-label="Pick UP Date">{response.pickTime}</td>
                          <td data-label="Pick Up Time">
                            {response.pickUpTime}
                          </td>
                          <td data-label="Drop OFF Date">
                            {response.dropTime}
                          </td>
                          <td data-label="Drop OFF Time">
                            {response.dropUpTime}
                          </td>
                          <td data-label="Car Type">{response.carType}</td>
                          <td data-label="Create Date & Time">
                            {response.createDateTime}
                          </td>
                          <td data-label="Address">{response.address}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="no-record-found-style">
                No Record Found.
              </p>
            )}
          </div>
        )}

        <div className="book-banner container">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>
                Book Self Drive Car, Car for Weddings, Car with Driver, Rental
                Car for Outings, Parties, and More with AgiroCabs for all your
                car rental needs!
              </h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>+91 9006484700</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default VendorLogin;
