import { Link } from "react-router-dom";
import Logo from "../images/logo/agiro-cabs-logo.png";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  return (
    <>
      <nav>
        {/* mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar__close">
            <i className="fa-solid fa-xmark"></i>
          </div>
          <ul className="mobile-navbar__links">
            <li>
              <FontAwesomeIcon icon={faPhone} />
              <Link onClick={openNav} to="/">
                +91 9006484700
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/about">
                About
              </Link>
            </li>
            {/* <li>
              <Link onClick={openNav} to="/models">
                Models
              </Link>
            </li> */}
            <li>
              <Link onClick={openNav} to="/testimonials">
                Testimonials
              </Link>
            </li>
            {/* <li>
              <Link onClick={openNav} to="/team">
                Our Team
              </Link>
            </li> */}
            <li>
              <Link onClick={openNav} to="/contact">
                Contact
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/ourPolicy">
                Our Policy
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/vendorLogin">
                Vendor Login
              </Link>
            </li>
            <li>
              <Link onClick={openNav} className="navbar__buttons__register" to="/signIn">
                Admin Access
              </Link>
            </li>
          </ul>
        </div>

        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          <ul className="navbar__links">
            <li className="phone-link">
              <FontAwesomeIcon icon={faPhone} />
              <Link className="home-link" to="#">
                +91 9006484700
              </Link>
            </li>
            <li>
              <Link className="home-link" to="/">
                Home
              </Link>
            </li>
            <li>
              {" "}
              <Link className="about-link" to="/about">
                About
              </Link>
            </li>
            <li>
              {" "}
              {/* <Link className="models-link" to="/models">
                Vehicle Models
              </Link> */}
            </li>
            <li>
              {" "}
              <Link className="testi-link" to="/testimonials">
                Testimonials
              </Link>
            </li>
            {/* <li>
              {" "}
              <Link className="team-link" to="/team">
                Our Team
              </Link>
            </li> */}
            <li>
              {" "}
              <Link className="contact-link" to="/contact">
                Contact
              </Link>
            </li>
            <li>
              {" "}
              <Link className="policy-link"  to="/ourPolicy">
                Our Policy
              </Link>
            </li>
            <li>
              {" "}
              <Link className="policy-link"  to="/vendorLogin">
                Vendor Login
              </Link>
            </li>
          </ul>
          <div className="navbar__buttons">
            <Link className="navbar__buttons__register" to="/signIn">
              Admin Access
            </Link>
          </div>

          {/* mobile */}
          <div className="mobile-hamb" onClick={openNav}>
            <i className="fa-solid fa-bars"></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
