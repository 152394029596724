import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { useNavigate } from "react-router-dom";
import "../Pages/SignIn.scss";
import config from "../config";
import { ClipLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faUser,
  faMotorcycle,
  faIndianRupeeSign,
  faStore,
} from "@fortawesome/free-solid-svg-icons";

function SignIn() {
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [adminFlag, setAdminFlag] = useState(true);
  const [spinnerFlag, setSpinnerFlag] = useState(false);
  const [filters, setFilters] = useState({
    firstName: "",
  });
  const [filteredData, setFilteredData] = useState([]);
  const [filteredWeddingData, setFilteredWeddingData] = useState([]);
  const [filteredWithDriverData, setFilteredWithDriverData] = useState([]);
  const [filteredBikeBookingData, setFilteredBikeBookingData] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [userInfoRespList, setUserInfoRespList] = useState("");
  const [weddingInfoResponseList, setWeddingInfoResponseList] = useState("");
  const [bikeBookingInfoDetails, setBikeBookingInfoDetails] = useState("");
  const [bookingWithDriverDetails, setBookingWithDriverDetails] = useState("");
  const [vendorsDetails, setVendorsDetails] = useState("");
  const [filteredVendorData, setFilteredVendorData] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [modal, setModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleTabChange = (tab) => {
    if (tab === "selfBookDetails") {
      getUserInfoDetails();
    }
    if (tab === "weddingBookDetails") {
      getWeddingInfoDetails();
    }
    if (tab === "selfBookBike") {
      getBikeInfoDetails();
    }
    if (tab === "withDriver") {
      getBookingWithDriverDetails();
    }
    if (tab === "vendorDetails") {
      getVendordDetails();
    }
    setActiveTab(tab);
  };

  const navigate = useNavigate();

  const confirmAccess = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setAdminFlag(false);
    setSpinnerFlag(true);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/adminAccess";
      } else {
        url = config.apiUrl + "/adminAccess";
      }

      const adminData = {
        userName: userName,
        password: password,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(adminData),
      });
      const data = await response.json();

      if (response.ok) {
        setAdminFlag(false);
      } else {
        setAdminFlag(true);
        setErrorMsg("Error in fetching data.");
      }
    } catch (error) {
      setAdminFlag(true);
      setErrorMsg(userName + " don't have the necessary access permissions.");
      console.error("Error in fetching data:", error);
    } finally {
      setUserName("");
      setPassword("");
      setSpinnerFlag(false);
    }
  };

  const getUserInfoDetails = async () => {
    setErrorMsg("");
    setAdminFlag(false);
    setSpinnerFlag(true);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/userInfoDetails";
      } else {
        url = config.apiUrl + "/userInfoDetails";
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          data.forEach((element) => {
            if (element.aadhaarFileData) {
              element.aadhaarFileBase64 = convertToBase64(
                element.aadhaarFileData,
                element.aadhaarFileType
              );
            }
            if (element.dlFileData) {
              element.dlFileBase64 = convertToBase64(
                element.dlFileData,
                element.dlFileType
              );
            }
          });
          setUserInfoRespList(data);
        }
      }
    } catch (error) {
      setAdminFlag(true);
      setErrorMsg("Error in fetching data.");
      console.error("Error in fetching data:", error);
    } finally {
      setUserName("");
      setPassword("");
      setSpinnerFlag(false);
    }
  };

  const getWeddingInfoDetails = async () => {
    setErrorMsg("");
    setAdminFlag(false);
    setSpinnerFlag(true);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/weddingInfoDetails";
      } else {
        url = config.apiUrl + "/weddingInfoDetails";
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          data.forEach((element) => {
            if (element.aadhaarFileData) {
              element.aadhaarFileBase64 = convertToBase64(
                element.aadhaarFileData,
                element.aadhaarFileType
              );
            }
          });
          setWeddingInfoResponseList(data);
        }
      }
    } catch (error) {
      setAdminFlag(true);
      setErrorMsg("Error in fetching data.");
      console.error("Error in fetching data:", error);
    } finally {
      setSpinnerFlag(false);
    }
  };

  const getBikeInfoDetails = async () => {
    setErrorMsg("");
    setAdminFlag(false);
    setSpinnerFlag(true);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/bikeInfoDetails";
      } else {
        url = config.apiUrl + "/bikeInfoDetails";
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          setBikeBookingInfoDetails(data);
        }
      }
    } catch (error) {
      setAdminFlag(true);
      setErrorMsg("Error in fetching data.");
      console.error("Error in fetching data:", error);
    } finally {
      setUserName("");
      setPassword("");
      setSpinnerFlag(false);
    }
  };

  const getBookingWithDriverDetails = async () => {
    setErrorMsg("");
    setAdminFlag(false);
    setSpinnerFlag(true);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/driverInfoDetails";
      } else {
        url = config.apiUrl + "/driverInfoDetails";
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          setBookingWithDriverDetails(data);
        }
      }
    } catch (error) {
      setAdminFlag(true);
      setErrorMsg(userName + " don't have the necessary access permissions.");
      console.error("Error in fetching data:", error);
    } finally {
      setUserName("");
      setPassword("");
      setSpinnerFlag(false);
    }
  };

  const getVendordDetails = async () => {
    setErrorMsg("");
    setAdminFlag(false);
    setSpinnerFlag(true);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/getVendorsDetails";
      } else {
        url = config.apiUrl + "/getVendorsDetails";
      }

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.length > 0) {
          setVendorsDetails(data);
        }
      }
    } catch (error) {
      setAdminFlag(true);
      setErrorMsg("Error in fetching data.");
      console.error("Error in fetching data:", error);
    } finally {
      setUserName("");
      setPassword("");
      setSpinnerFlag(false);
    }
  };

  const convertToBase64 = (base64Data, fileType) => {
    return `data:${fileType};base64,${base64Data}`;
  };

  const handleDownload = (base64Data, fileName) => {
    const link = document.createElement("a");
    link.href = base64Data;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const acceptRejectHandler = async (userId, bookingAcceptReject) => {
    setSpinnerFlag(true);
    try {
      if (bookingAcceptReject === "NO") {
        let url = "";
        if (process.env.NODE_ENV === "development") {
          url = config.apiUrl + "/bookingAcceptReject";
        } else {
          url = config.apiUrl + "/bookingAcceptReject";
        }

        const bookingAcceptRejectReq = {
          userId: userId,
          bookingAcceptReject: bookingAcceptReject,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookingAcceptRejectReq),
        });
        const data = await response.text();
        if (data) {
          await getUserInfoDetails();
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSpinnerFlag(false);
    }
  };

  const deleteHandler = async (userId, deleteSw) => {
    setSpinnerFlag(true);
    try {
      if (deleteSw === "N") {
        let url = "";
        if (process.env.NODE_ENV === "development") {
          url = config.apiUrl + "/bookingAcceptReject";
        } else {
          url = config.apiUrl + "/bookingAcceptReject";
        }

        const bookingAcceptRejectReq = {
          userId: userId,
          deleteSw: deleteSw,
        };

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookingAcceptRejectReq),
        });
        const data = await response.text();
        if (data) {
          await getUserInfoDetails();
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSpinnerFlag(false);
    }
  };

  const openVendorModal = async (vendor) => {
    setSelectedVendor(vendor);
    setErrorMsg("");
    setModal(!modal);
    const modalDiv = document.querySelector(".booking-modal");
    modalDiv.scroll(0, 0);
  };

  useEffect(() => {
    if (userInfoRespList) {
      const filtered = userInfoRespList.filter((response) => {
        return (
          !filters.firstName ||
          response.firstName
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  }, [filters, userInfoRespList]);

  useEffect(() => {
    if (weddingInfoResponseList) {
      const filtered = weddingInfoResponseList.filter((response) => {
        return (
          !filters.firstName ||
          response.firstName
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        );
      });
      setFilteredWeddingData(filtered);
    }
  }, [filters, weddingInfoResponseList]);

  useEffect(() => {
    if (bookingWithDriverDetails) {
      const filtered = bookingWithDriverDetails.filter((response) => {
        return (
          !filters.firstName ||
          response.firstName
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        );
      });
      setFilteredWithDriverData(filtered);
    }
  }, [filters, bookingWithDriverDetails]);

  useEffect(() => {
    if (bikeBookingInfoDetails) {
      const filtered = bikeBookingInfoDetails.filter((response) => {
        return (
          !filters.firstName ||
          response.firstName
            .toLowerCase()
            .includes(filters.firstName.toLowerCase())
        );
      });
      setFilteredBikeBookingData(filtered);
    }
  }, [filters, bikeBookingInfoDetails]);

  useEffect(() => {
    if (vendorsDetails) {
      const filtered = vendorsDetails.filter((response) => {
        return (
          !filters.vendorName ||
          response.vendorName
            .toLowerCase()
            .includes(filters.vendorName.toLowerCase())
        );
      });
      setFilteredVendorData(filtered);
    }
  }, [filters, vendorsDetails]);

  useEffect(() => {
    if (successMsg) {
      const timer = setTimeout(() => {
        setSuccessMsg("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMsg]);

  useEffect(() => {
    if (setErrorMsg) {
      const timer = setTimeout(() => {
        setErrorMsg("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMsg]);

  const updateVendorDetails = async (vendorsDetails) => {
    console.log("------------", vendorsDetails);
    try {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = config.apiUrl + "/updateVendorDetails";
      } else {
        url = config.apiUrl + "/updateVendorDetails";
      }
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(vendorsDetails),
      });
      if (response.ok) {
        const data = await response.json();
        getVendordDetails();
        setSuccessMsg("Vendor Updated Successfully.");
      } else {
        setErrorMsg("Network response was not ok.");
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      setErrorMsg("something went wrong please try later.");
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateVendorDetails(selectedVendor);
    setModal(!modal);
  };

  return (
    <>
      <section className="admin-page">
        <div
          onClick={() => openVendorModal(selectedVendor)}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>
        <HeroPages name="Admin Panel" />
        {adminFlag ? (
          <div className="container">
            {errorMsg ? <div className="error-msg">{errorMsg}</div> : null}

            <div className="admin-div">
              <div className="admin-div__text">
                <h2>
                  Only Admin Can Access This. User Can directly book a car.
                </h2>
              </div>
              <div className="admin-div__form">
                <form>
                  <label>
                    User Name <b>*</b>
                  </label>
                  <input
                    type="text"
                    required
                    value={userName}
                    onChange={handleUserName}
                    placeholder='E.g: "Abc@123"'
                  ></input>
                  <label>
                    Password <b>*</b>
                  </label>
                  <input
                    type="password"
                    required
                    value={password}
                    onChange={handlePassword}
                    placeholder="password"
                  ></input>
                  <button type="submit" onClick={confirmAccess}>
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp;
                    Admin Login
                  </button>
                </form>
              </div>
            </div>
          </div>
        ) : spinnerFlag ? (
          <div className="spinner-container spinner-style">
            <ClipLoader color="#123abc" spinnerFlag={spinnerFlag} size={200} />
          </div>
        ) : (
          <div className="container">
            {/* <div className="tabs">
              <button
                className={`${
                  activeTab === "selfBookDetails" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("selfBookDetails")}
              >
                Self Drive Car &nbsp; <i className="fa-solid fa-car"></i>
              </button>
              <button
                className={`${
                  activeTab === "weddingBookDetails" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("weddingBookDetails")}
              >
                Wedding Booking &nbsp;{" "}
                <FontAwesomeIcon icon={faHeart} className="ring-icon" />
              </button>
              <button
                className={`${
                  activeTab === "withDriver" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("withDriver")}
              >
                Car With Driver &nbsp;{" "}
                <FontAwesomeIcon icon={faUser} className="user-icon" />
              </button>
              <button
                className={`${
                  activeTab === "selfBookBike" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("selfBookBike")}
              >
                Self Drive Bike &nbsp;{" "}
                <FontAwesomeIcon icon={faMotorcycle} className="car-icon" />
              </button>
              <button
                className={`${
                  activeTab === "vendorDetails" ? "active" : ""
                } tab-signIn-style`}
                onClick={() => handleTabChange("vendorDetails")}
              >
                Vendor Details &nbsp;{" "}
                <FontAwesomeIcon icon={faStore} className="vendor-icon" />
              </button>
            </div> */}

            <div className="cards">
              <div
                className={`card ${
                  activeTab === "selfBookDetails" ? "active" : ""
                }`}
                onClick={() => handleTabChange("selfBookDetails")}
              >
                <div className="card-content" style={{ display: "contents" }}>
                  <h3>Self Drive Car</h3>
                  <i className="fa-solid fa-car"></i>
                </div>
              </div>

              <div
                className={`card ${
                  activeTab === "selfBookBike" ? "active" : ""
                }`}
                onClick={() => handleTabChange("selfBookBike")}
              >
                <div className="card-content" style={{ display: "contents" }}>
                  <h3>Self Drive Bike</h3>
                  <FontAwesomeIcon icon={faMotorcycle} className="car-icon" />
                </div>
              </div>

              <div
                className={`card ${activeTab === "withDriver" ? "active" : ""}`}
                onClick={() => handleTabChange("withDriver")}
              >
                <div className="card-content" style={{ display: "contents" }}>
                  <h3>Car With Driver</h3>
                  <FontAwesomeIcon icon={faUser} className="user-icon" />
                </div>
              </div>

              <div
                className={`card ${
                  activeTab === "weddingBookDetails" ? "active" : ""
                }`}
                onClick={() => handleTabChange("weddingBookDetails")}
              >
                <div className="card-content" style={{ display: "contents" }}>
                  <h3>Wedding Booking</h3>
                  <FontAwesomeIcon icon={faHeart} className="ring-icon" />
                </div>
              </div>

              <div
                className={`card ${
                  activeTab === "vendorDetails" ? "active" : ""
                }`}
                onClick={() => handleTabChange("vendorDetails")}
              >
                <div className="card-content" style={{ display: "contents" }}>
                  <h3>Vendors Detail</h3>
                  <FontAwesomeIcon icon={faStore} className="vendor-icon" />
                </div>
              </div>
            </div>

            {activeTab === "selfBookDetails" ? (
              userInfoRespList ? (
                <div className="table-container">
                  <div className="filters-style">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Filter By Name"
                      value={filters.firstName}
                      onChange={handleFilterChange}
                      className="filter-input-box"
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Age</th>
                        <th>Phone NO</th>
                        <th>Pick UP Location</th>
                        <th>Drop OFF Location</th>
                        <th>Pick UP Date</th>
                        <th>Pick UP Time</th>
                        <th>Drop OFF Date</th>
                        <th>Drop OFF Time</th>
                        <th>Car Type</th>
                        <th>Create Date & Time</th>
                        <th>Address</th>
                        <th>Aadhaar img</th>
                        <th>DL img</th>
                        <th>Accept/Reject</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData &&
                        filteredData.map((response) => (
                          <tr key={response.userId}>
                            <td data-label="First Name">
                              {response.firstName}
                            </td>
                            <td data-label="Last Name">{response.lastName}</td>
                            <td data-label="Age">{response.age}</td>
                            <td data-label="Phone NO">
                              {response.phoneNumber}
                            </td>
                            <td data-label="Pick UP Location">
                              {response.pickUpLocation}
                            </td>
                            <td data-label="Drop OFF Location">
                              {response.dropOffLocation}
                            </td>
                            <td data-label="Pick UP Date">
                              {response.pickTime}
                            </td>
                            <td data-label="Pick Up Time">
                              {response.pickUpTime}
                            </td>
                            <td data-label="Drop OFF Date">
                              {response.dropTime}
                            </td>
                            <td data-label="Drop OFF Time">
                              {response.dropUpTime}
                            </td>
                            <td data-label="Car Type">{response.carType}</td>
                            <td data-label="Create Date & Time">
                              {response.createDateTime}
                            </td>
                            <td data-label="Address">{response.address}</td>
                            <td data-label="Aadhaar img">
                              {response.aadhaarFileBase64 ? (
                                <img
                                  src={response.aadhaarFileBase64}
                                  alt="Aadhaar"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDownload(
                                      response.aadhaarFileBase64,
                                      "aadhaar_image.jpg"
                                    )
                                  }
                                />
                              ) : (
                                "No Aadhaar Image"
                              )}
                            </td>
                            <td data-label="DL img">
                              {response.dlFileBase64 ? (
                                <img
                                  src={response.dlFileBase64}
                                  alt="Driving License"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDownload(
                                      response.dlFileBase64,
                                      "driving_license.jpg"
                                    )
                                  }
                                />
                              ) : (
                                "No DL Image"
                              )}
                            </td>
                            <td data-label="Accept/Reject">
                              <button
                                type="submit"
                                className="accept-button"
                                onClick={() =>
                                  acceptRejectHandler(
                                    response.userId,
                                    response.bookingAcceptReject
                                  )
                                }
                              >
                                {response.bookingAcceptReject}
                              </button>
                            </td>
                            <td data-label="Delete">
                              <button
                                type="submit"
                                className="reject-button"
                                onClick={() =>
                                  deleteHandler(
                                    response.userId,
                                    response.deleteSw
                                  )
                                }
                              >
                                <i className="fa-solid fa-trash"></i>&nbsp;
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-record-found-style">
                  No Record Found for Wedding
                </p>
              )
            ) : null}

            {activeTab === "weddingBookDetails" ? (
              weddingInfoResponseList ? (
                <div className="table-container">
                  <div className="filters-style">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Filter By Name"
                      value={filters.firstName}
                      onChange={handleFilterChange}
                      className="filter-input-box"
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone NO</th>
                        <th>Pick UP Location</th>
                        <th>Drop OFF Location</th>
                        <th>Pick UP Date</th>
                        <th>Pick UP Time</th>
                        <th>Drop OFF Date</th>
                        <th>Drop OFF Time</th>
                        <th>Car Type</th>
                        <th>Create Date & Time</th>
                        <th>Address</th>
                        <th>Aadhaar img</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredWeddingData &&
                        filteredWeddingData.map((response) => (
                          <tr key={response.userId}>
                            <td data-label="First Name">
                              {response.firstName}
                            </td>
                            <td data-label="Last Name">{response.lastName}</td>
                            <td data-label="Phone NO">
                              {response.phoneNumber}
                            </td>
                            <td data-label="Pick UP Location">
                              {response.pickUpLocation}
                            </td>
                            <td data-label="Drop OFF Location">
                              {response.dropOffLocation}
                            </td>
                            <td data-label="Pick UP Date">
                              {response.pickUpDate}
                            </td>
                            <td data-label="Pick Up Time">
                              {response.pickUpTime}
                            </td>
                            <td data-label="Drop OFF Date">
                              {response.dropOffDate}
                            </td>
                            <td data-label="Drop OFF Time">
                              {response.dropOffTime}
                            </td>
                            <td data-label="Car Type">{response.carType}</td>
                            <td data-label="Create Date & Time">
                              {response.createDateTime}
                            </td>
                            <td data-label="Address">{response.address}</td>
                            <td data-label="Aadhaar img">
                              {response.aadhaarFileBase64 ? (
                                <img
                                  src={response.aadhaarFileBase64}
                                  alt="Aadhaar"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleDownload(
                                      response.aadhaarFileBase64,
                                      "aadhaar_image.jpg"
                                    )
                                  }
                                />
                              ) : (
                                "No Aadhaar Image"
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-record-found-style">
                  No Record Found for Wedding
                </p>
              )
            ) : null}

            {activeTab === "selfBookBike" ? (
              bikeBookingInfoDetails ? (
                <div className="table-container">
                  <div className="filters-style">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Filter By Name"
                      value={filters.firstName}
                      onChange={handleFilterChange}
                      className="filter-input-box"
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone NO</th>
                        <th>Pick UP Location</th>
                        <th>Drop OFF Location</th>
                        <th>Pick UP Date</th>
                        <th>Pick UP Time</th>
                        <th>Drop OFF Date</th>
                        <th>Drop OFF Time</th>
                        <th>Bike Type</th>
                        <th>Create Date & Time</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredBikeBookingData &&
                        filteredBikeBookingData.map((response) => (
                          <tr key={response.userId}>
                            <td data-label="First Name">
                              {response.firstName}
                            </td>
                            <td data-label="Last Name">{response.lastName}</td>
                            <td data-label="Phone NO">
                              {response.phoneNumber}
                            </td>
                            <td data-label="Pick UP Location">
                              {response.pickUpLocation}
                            </td>
                            <td data-label="Drop OFF Location">
                              {response.dropOffLocation}
                            </td>
                            <td data-label="Pick UP Date">
                              {response.pickUpDate}
                            </td>
                            <td data-label="Pick Up Time">
                              {response.pickUpTime}
                            </td>
                            <td data-label="Drop OFF Date">
                              {response.dropOffDate}
                            </td>
                            <td data-label="Drop OFF Time">
                              {response.dropOffTime}
                            </td>
                            <td data-label="Bike Type">{response.bikeType}</td>
                            <td data-label="Create Date & Time">
                              {response.createDateTime}
                            </td>
                            <td data-label="Address">{response.address}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-record-found-style">
                  No Record Found for Self Drive Bike
                </p>
              )
            ) : null}

            {activeTab === "withDriver" ? (
              bookingWithDriverDetails ? (
                <div className="table-container">
                  <div className="filters-style">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="Filter By Name"
                      value={filters.firstName}
                      onChange={handleFilterChange}
                      className="filter-input-box"
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone NO</th>
                        <th>Pick UP Location</th>
                        <th>Drop OFF Location</th>
                        <th>Pick UP Date</th>
                        <th>Pick UP Time</th>
                        <th>Drop OFF Date</th>
                        <th>Drop OFF Time</th>
                        <th>Car Type</th>
                        <th>Create Date & Time</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredWithDriverData &&
                        filteredWithDriverData.map((response) => (
                          <tr key={response.userId}>
                            <td data-label="First Name">
                              {response.firstName}
                            </td>
                            <td data-label="Last Name">{response.lastName}</td>
                            <td data-label="Phone NO">
                              {response.phoneNumber}
                            </td>
                            <td data-label="Pick UP Location">
                              {response.pickUpLocation}
                            </td>
                            <td data-label="Drop OFF Location">
                              {response.dropOffLocation}
                            </td>
                            <td data-label="Pick UP Date">
                              {response.pickUpDate}
                            </td>
                            <td data-label="Pick Up Time">
                              {response.pickUpTime}
                            </td>
                            <td data-label="Drop OFF Date">
                              {response.dropOffDate}
                            </td>
                            <td data-label="Drop OFF Time">
                              {response.dropOffTime}
                            </td>
                            <td data-label="Car Type">{response.carType}</td>
                            <td data-label="Create Date & Time">
                              {response.createDateTime}
                            </td>
                            <td data-label="Address">{response.address}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-record-found-style">
                  No Record Found for Booking With Driver
                </p>
              )
            ) : null}

            {successMsg ? (
              <div
                className="booking-done"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {successMsg}
              </div>
            ) : errorMsg ? (
              <div
                className="booking-done"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {errorMsg}
              </div>
            ) : null}

            {activeTab === "vendorDetails" ? (
              vendorsDetails ? (
                <div className="table-container">
                  <div className="filters-style">
                    <input
                      type="text"
                      name="vendorName"
                      placeholder="Filter By Name"
                      value={filters.vendorName}
                      onChange={handleFilterChange}
                      className="filter-input-box"
                    />
                  </div>
                  <table className="responsive-table">
                    <thead>
                      <tr>
                        <th>Vendor Name</th>
                        <th>Vehicle Name</th>
                        <th>Address</th>
                        <th>Amount/Hour</th>
                        <th>Vehicle Seater</th>
                        <th>Mobile Number</th>
                        <th>Registration Type</th>
                        <th>Vehicle Type</th>
                        <th>Fuel Type</th>
                        <th>Booking Status</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredVendorData &&
                        filteredVendorData.map((response) => (
                          <tr key={response.vendorId}>
                            <td data-label="Vendor Name">
                              {response.vendorName}
                            </td>
                            <td data-label="Vehicle Name">
                              {response.vendorVehicleName}
                            </td>
                            <td data-label="Address">
                              {response.vendorAddress}
                            </td>
                            <td data-label="Amount/Hour">
                              {response.vendorVehicleAmount}
                            </td>
                            <td data-label="Vehicle Seater">
                              {response.vendorVehicleSeat}
                            </td>
                            <td data-label="Mobile Number">
                              {response.vendorMobNum}
                            </td>
                            <td data-label="Registration Type">
                              {response.registerType}
                            </td>
                            <td data-label="Vehicle Type">
                              {response.vendorVehicleType}
                            </td>
                            <td data-label="Fuel Type">
                              {response.vendorVehicleFuelType}
                            </td>
                            <td data-label="Booking Status">
                              {response.vehicleBookingStatus}
                            </td>
                            <td data-label="View">
                              <button
                                type="submit"
                                className="edit-icon"
                                onClick={() => openVendorModal(response)}
                              >
                                {response.bookingAcceptReject}
                                <i className="fas fa-pencil-alt"></i>&nbsp;
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="no-record-found-style">
                  No Record Found for Booking With Driver
                </p>
              )
            ) : null}
          </div>
        )}

        <div className="book-banner container">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>
                Book Self Drive Car, Car for Weddings, Car with Driver, Rental
                Car for Outings, Parties, and More with AgiroCabs for all your
                car rental needs!
              </h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>+91 9006484700</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        <div className="booking-modal__title">
          <h2>Vendor Information</h2>
          <i
            onClick={() => openVendorModal(selectedVendor)}
            className="fa-solid fa-xmark"
          ></i>
        </div>

        {/* personal info */}
        <div className="booking-modal__person-info">
          <form className="info-form" onSubmit={handleSubmit}>
            <div className="info-form__2col">
              <span>
                <label>
                  Name <b>*</b>
                </label>
                <input
                  type="text"
                  className="test-box"
                  maxLength={30}
                  placeholder="Enter your Name"
                  value={selectedVendor.vendorName}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorName: e.target.value,
                    })
                  }
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Mobile Number <b>*</b>
                </label>
                <input
                  className="test-box"
                  value={selectedVendor.vendorMobNum}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorMobNum: e.target.value,
                    })
                  }
                  type="tel"
                  placeholder="Enter your mobile number"
                  maxLength={12}
                  minLength={12}
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>
            <div className="info-form__2col">
              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  type="text"
                  className="test-box"
                  maxLength={100}
                  placeholder="Ender your address"
                  value={selectedVendor.vendorAddress}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorAddress: e.target.value,
                    })
                  }
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Vehicle Name <b>*</b>
                </label>
                <input
                  type="text"
                  className="test-box"
                  maxLength={20}
                  placeholder="Ender your vehicle name"
                  value={selectedVendor.vendorVehicleName}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorVehicleName: e.target.value,
                    })
                  }
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Registration Type <b>*</b>
                </label>
                <select
                  required
                  value={selectedVendor.registerType}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      registerType: e.target.value,
                    })
                  }
                  className="test-box select-input-color"
                >
                  <option>Select</option>
                  <option value="With Driver">With Driver</option>
                  <option value="Without Driver">Without Driver</option>
                  <option value="Both">Both</option>
                </select>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Booking Status <b>*</b>
                </label>
                <select
                  required
                  value={selectedVendor.vehicleBookingStatus}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vehicleBookingStatus: e.target.value,
                    })
                  }
                  className="test-box select-input-color"
                >
                  <option>Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  Vehicle Type <b>*</b>
                </label>
                <select
                  value={selectedVendor.vendorVehicleType}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorVehicleType: e.target.value,
                    })
                  }
                  className="test-box select-input-color"
                >
                  <option>Select Vehicle Type</option>
                  <option value="Automatic">Automatic</option>
                  <option value="Manual">Manul</option>
                </select>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Fuel Type <b>*</b>
                </label>
                <select
                  required
                  value={selectedVendor.vendorVehicleFuelType}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorVehicleFuelType: e.target.value,
                    })
                  }
                  className="test-box select-input-color"
                >
                  <option>Select Fuel Type</option>
                  <option value="Petrol">Petrol</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Petrol/CNG">Petrol/CNG</option>
                </select>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  Amount <b>*</b>
                </label>
                <input
                  type="text"
                  className="test-box"
                  maxLength={20}
                  placeholder="Enter Amount"
                  value={selectedVendor.vendorVehicleAmount}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorVehicleAmount: e.target.value,
                    })
                  }
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Seating Capacity <b>*</b>
                </label>
                <select
                  required
                  value={selectedVendor.vendorVehicleSeat}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorVehicleSeat: e.target.value,
                    })
                  }
                 className="test-box select-input-color"
                >
                  <option>Select</option>
                  <option value="5">5</option>
                  <option value="7">7</option>
                </select>
                <p className="error-modal ">This field is required.</p>
              </span>
              <span>
                <label>
                  Polution Expiry Date <b>*</b>
                </label>
                <input
                  type="date"
                  className="test-box"
                  maxLength={1}
                  placeholder="Enter polution expiry date"
                  value={selectedVendor.vendorVehiclePollutionExpiry}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorVehiclePollutionExpiry: e.target.value,
                    })
                  }
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
              <span>
                <label>
                  Ensurance Expiry Date <b>*</b>
                </label>
                <input
                  type="date"
                  className="test-box"
                  maxLength={1}
                  placeholder="Enter ensurance expiry date"
                  value={selectedVendor.vendorVehicleInsuranceExpiry}
                  onChange={(e) =>
                    setSelectedVendor({
                      ...selectedVendor,
                      vendorVehicleInsuranceExpiry: e.target.value,
                    })
                  }
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div
              onClick={() => updateVendorDetails(selectedVendor)}
              className="reserve-button"
            >
              <button>Update Vendor</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignIn;
